export default {
    Auth: {
        identityPoolId: 'us-east-1:bb09b48d-52c5-4798-838c-6d9f0f912c3a',
        region: 'us-east-1',
        userPoolId: 'us-east-1_dSUtlaeFL',
        userPoolWebClientId: '2uo48uaafkuakle5q3p9j7lglm',
        authenticationFlowType: 'USER_PASSWORD_AUTH'
    },
    API: {
        aws_appsync_graphqlEndpoint:
            'https://kqrmdao2nrbmdbyvfqdhbjy5ku.appsync-api.us-east-1.amazonaws.com/graphql',
        aws_appsync_region: 'us-east-1',
        aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS'
    },
    Storage: {
        AWSS3: {
            bucket: 'rhenium-dev-storage-graphql',
            region: 'us-east-1'
        }
    }
}
